<template>
  <div class="table-responsive">
    <div class="data-container" v-if="!loading">
      <v-col md="12" sm="12" style="text-align: center">
        <label class="search" for="inpt_search">
          <input
            id="inpt_search"
            class="input_search"
            @focus="inputFocus"
            @blur="inputBlur"
            type="text"
            v-model.trim="search"
            @keyup.enter="getAclData(page)"
          />
          <v-icon
            title="Search..."
            @click="getAclData(page)"
            class="search-icon"
            >search</v-icon
          >
        </label>
      </v-col>
      <button
        v-if="editBtnVisible"
        class="btn btn-success update_acl"
        @click.prevent="updateAclData"
        :disabled="loading"
      >
        {{ $t("Update ACL") }}
      </button>

      <table class="table table-bordered" v-can="'show-acl-matrix'">
        <thead>
          <tr class="master">
            <th>{{ $t("Actions") }}</th>
            <th
              v-if="roles_and_users.roles"
              :colspan="roles_and_users.roles.length"
            >
              {{ $t("Roles") }}
            </th>
            <!-- <th :colspan="colspan">{{ $t("Users") }}</th> -->
          </tr>
          <tr>
            <th></th>
            <!-- <th></th> -->
            <th
              v-for="(item, index) in roles_and_users.roles"
              :class="index"
              :key="item"
            >
              {{ item }}
            </th>
            <!-- <th
              v-for="(item, index) in roles_and_users.users"
              :class="index"
              :key="item"
            >
              {{ item }}
            </th> -->
          </tr>
        </thead>
        <tbody v-for="(permissionsArray, mod) in permissionsMatrix" :key="mod">
          <!-- data-toggle="collapse"
          :data-target="'#collapse' + mod.replace(/\s/g, '')"
          aria-expanded="true"
          :aria-controls="'#collapse' + mod.replace(/\s/g, '')" -->
          <tr class="module">
            <td
              :colspan="roles_and_users.roles.length + 1 + colspan"
              class="module"
            >
              <b>{{ mod }}</b> {{ $t("Module") }}
            </td>
          </tr>
          <tr
            v-for="(values, permission) in permissionsArray"
            :key="permission"
          >
            <!-- :id="'collapse' + mod.replace(/\s/g, class="collapse" -->
            <td>
              {{
                permission.split("-").join(" ").charAt(0).toUpperCase() +
                permission.split("-").join(" ").slice(1)
              }}
            </td>
            <td v-for="(checkVal, i) in values.roles" :key="i">
              <!-- <input
              type="checkbox"
              class="checkbox"
              :checked="checkVal"
              v-model="values.roles[i]"
              name=""
              id=""
            /> -->
              <p-check
                class="p-icon p-rotate"
                color="success"
                style="font-size: 25px"
                v-model="values.roles[i]"
                @change="
                  addToAclArray(permission, i, 'role', values.roles[i], mod)
                "
              >
                <i slot="extra" class="icon mdi mdi-check"></i>
              </p-check>
            </td>
            <!-- <td v-for="(checkVal, i) in values.users" :key="i">
              <p-check
                class="p-icon p-rotate"
                color="success"
                style="font-size: 25px"
                v-model="values.users[i]"
                @change="
                  addToAclArray(permission, i, 'user', values.users[i], mod)
                "
              >
                <i slot="extra" class="icon mdi mdi-check"></i>
              </p-check>
            </td> -->
          </tr>
        </tbody>
      </table>
      <div class="text-center pt-2" v-can="'show-acl-matrix'">
        <v-pagination
          v-if="length > 0 && totalItems > 0"
          v-model="page"
          :length="length"
          :circle="circle"
          :page="page"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          :disabled="disablePagination"
        ></v-pagination>
        <!-- :total-visible="totalVisible" -->
      </div>
      <v-snackbar
        v-model="snack"
        :timeout="snackTime"
        :color="snackColor"
        :right="true"
        :top="true"
      >
        <!-- {{ snackText }} -->

        <span class="white--text">
          {{ snackText }}
        </span>

        <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
      </v-snackbar>
    </div>
    <img :hidden="!loading" src="../../assets/img/preloader2.gif" alt="" />
  </div>
</template>

<script>
import axios from "axios";
import ACL from "../../acl";
export default {
  data() {
    return {
      disablePagination: false,
      permissionsMatrix: [],
      roles_and_users: [],
      updatedAclData: [],
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      //pagination options
      options: {
        itemsPerPage: 5,
      },
      page: 1,
      circle: true,
      length: 0,
      totalVisible: 0,
      totalItems: 0,
      // end pagination
      loading: false,
      editBtnVisible: ACL.checkPermission("edit-acl-matrix"),
      search: "",
      colspan: 1,
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.page = Number(this.$route.query.page);
        }
        this.getAclData(this.page);
      },
    },
    page: function () {
      this.$router.push(
        {
          path: "/role_management?page=" + this.page,
        },
        () => {}
      );
    },
    // search: {
    //   handler() {
    //     this.page = 1;
    //     this.getAclData(this.page);
    //   },
    //   deep: true,
    // },
  },
  methods: {
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
        $(".input_search").parent("label").removeClass("active");
      }
    },
    getAclData(page = 1) {
      this.disablePagination = true;
      this.loading = true;
      axios
        .get(
          this.getApiUrl + "/acl/roles?page=" + page + "&search=" + this.search,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.disablePagination = false;
          this.roles_and_users = response.data.data.roles_and_users;
          // this.colspan = Object.keys(this.roles_and_users.users).length;
          this.permissionsMatrix = response.data.data.permissionsMatrix;
          this.totalItems = response.data.data.pagination.total;
          this.length = response.data.data.pagination.last_page;
          // this.page =
          //   this.search != ""
          //     ? 1
          //     : Number(response.data.data.pagination.current_page);
          this.loading = false;
        });
    },
    // updateAcl() {
    //   this.loading = true;
    //   axios
    //     .post(
    //       this.getApiUrl + "/acl",
    //       { permissionsMatrix: this.permissionsMatrix },
    //       {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.token
    //           //the token is a variable which holds the token
    //         }
    //       }
    //     )
    //     .then(response => {
    //       if (response.data.status.error) {
    //         this.snack = true;
    //         this.snackColor = "danger";
    //         this.snackText = response.data.status.message;
    //       } else {
    //         this.snack = true;
    //         this.snackColor = "success";
    //         this.snackText = response.data.status.message;
    //       }
    //       this.loading = false;
    //     });
    // },
    updateAclData() {
      if (this.updatedAclData.length == 0) {
        console.log(this.$i18n.t("You didn't make any changes."));
        this.snack = true;
        this.snackColor = "error";
        this.snackText = this.$i18n.t("You didn't make any changes.");
      } else {
        this.loading = true;
        axios
          .post(
            this.getApiUrl + "/acl",
            { updatedAclData: this.updatedAclData },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            if (response.data.status.error) {
              this.snack = true;
              this.snackColor = "error";
              this.snackText = response.data.status.message;
            } else {
              this.snack = true;
              this.snackColor = "success";
              this.snackText = response.data.status.message;
            }
            this.loading = false;
            this.updatedAclData = [];
          });
      }
    },
    addToAclArray(permission, entity, type, value, mod = null) {
      this.updatedAclData.push({
        permission: permission,
        entity: entity,
        type: type,
        value: value,
        module_name: mod,
      });
    },
  },

  mounted() {
    if (this.$route.query.page !== undefined) {
      this.page = Number(this.$route.query.page);
    }
    this.getAclData(this.page);
    this.loading = true;
  },
};
</script>

<style scoped>
table th,
table td {
  text-align: center;
  vertical-align: middle;
}
table tr {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.module {
  background-color: #dbdbdb;
}
.master {
  background-color: #7295fb !important;
  color: #fff !important;
}
.checkbox {
  width: 30px;
  height: 30px;
}
.btn-success {
  top: 50%;
  position: fixed;
  right: 20px;
  width: 145px;
  box-shadow: 1px 3px 5px #4b4b4b;
  border-color: #7295fb !important;
  background-color: #7295fb !important;
}
img {
  top: 45%;
  position: fixed;
  right: 40%;
  width: 145px;
}
.update_acl {
  z-index: 2222 !important;
}
</style>
